import React from "react";
import { Link } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import { Button } from "@mui/material";

const styles = {
  floatingButton: {
    position: "fixed",
    bottom: "3rem",
    left: "3rem",
    backgroundColor: "#ffd500",
    "&:hover": {
      backgroundColor: "#d6b302",
    },
  },
};
export default function LoginButton(props) {
  const scrollToPackages = () => {
    const packagesElement = document.getElementById("packages");
    packagesElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Grid2 container xs={12}>
      <Grid2
        container
        xs={12}
        justifyContent={"flex-end"}
        sx={{ position: "absolute", zIndex: 3, color: "#ff0000", p: "1rem" }}
      >
        <Grid2 container xs={12}>
          <Button
            variant="contained"
            size="small"
            onClick={scrollToPackages}
            sx={styles.floatingButton}
          >
            همین الآن ثبت نام کن!
          </Button>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
