/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Paper,
  Alert,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosPublic from "../../common/axiosPublic";
import axiosPrivate from "../../common/axiosPrivate";
import axiosRetry from "axios-retry";

const styles = {
  paper: {
    width: "100%",
    maxWidth: "32rem",
    m: { xs: "1rem", sm: 0 },
  },
  innerCntnr: {
    p: { xs: "1.5rem", md: "2rem" },
  },
  text: {
    mb: "1.5rem",
  },
  textFldCntnr: {
    mb: "1rem",
  },
  originalPrice: css`
    text-decoration: line-through;
    font-size: 0.85rem;
    margin: 0 0.5rem;
  `,
  finalPrice: css`
    font-weight: 600;
    margin: 0 0.5rem;
  `,
  button: {
    color: "#fff",
    width: "100%",
    mb: "1rem",
  },
  forgetBtn: {
    width: { xs: "100%", md: "auto" },
  },
};

export default function LoginAndPurchase(props) {
  const [data, setData] = useState({
    phoneNumber: "",
    password: "",
    selectedPacks: [],
    totalPrice: 0,
    originalPrice: 0,
    packsList: [
      {
        value: "fullbody",
        label: "فول‌بادی",
      },
      {
        value: "aerobic",
        label: "هوازی",
      },
    ],
    prices: {},
    previousId: "",
    newId: "",
  });
  const [validation, setValidation] = useState({
    isPasswordValid: true,
    showPasswordError: false,
    isPacksValid: true,
    showPacksError: false,
    isValid: true,
    showPassword: false,
  });
  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [requestFired, setRequestFired] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchedPrices, setFetchedPrices] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const checkValidation = () => {
    let tempValidation = {
      isPasswordValid: true,
      showPasswordError: false,
      isPacksValid: true,
      showPacksError: false,
      isValid: true,
    };
    if (!data.password) {
      tempValidation = {
        ...tempValidation,
        isPasswordValid: false,
        showPasswordError: true,
        isValid: false,
      };
    }
    if (data.selectedPacks.length === 0) {
      tempValidation = {
        ...tempValidation,
        isPacksValid: false,
        showPacksError: true,
        isValid: false,
      };
    }
    setValidation((prevState) => {
      return { ...prevState, ...tempValidation };
    });
    return tempValidation;
  };

  const handleChange = (parameter) => (event) => {
    let v = event.target.value;
    // console.log(v);
    // console.log(v[0].value + "_original");
    setData((prevState) => {
      if (parameter === "packs") {
        return {
          ...prevState,
          selectedPacks: v,
          totalPrice:
            v.length === 0
              ? 0
              : v.length === 1
              ? data.prices[v[0].value]
              : v.length === 2 && data.prices.both,
          originalPrice:
            v.length === 0
              ? 0
              : v.length === 1
              ? data.prices[v[0].value + "_original"]
              : v.length === 2 && data.prices.both_original,
        };
      } else {
        return { ...prevState, [parameter]: v };
      }
    });
  };

  const handleClickShowPassword = () =>
    setValidation((previousState) => {
      return { ...previousState, showPassword: !validation.showPassword };
    });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  axiosRetry(axiosPrivate, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
      return retryCount * 1000; // time interval between retries
    },
    retryCondition: (error) => {
      // Conditional check the error status code
      if ([404, 408, 429, 500, 501, 503, 504].includes(error.response.status)) {
        return true;
      } else {
        return false; // Do not retry the others
      }
    },
  });

  const handleSetSelectedPacks = () => {
    if (props.packs) {
      setLoading(false);
      // console.log("setting packs from url");
      // console.log(data);
      // console.log(data.prices);
      if (props.packs === "fullbody") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: [data.packsList[0]],
            totalPrice: data.prices.fullbody,
            originalPrice: data.prices.fullbody_original,
          };
        });
      } else if (props.packs === "aerobic") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: [data.packsList[1]],
            totalPrice: data.prices.aerobic,
            originalPrice: data.prices.aerobic_original,
          };
        });
      } else if (props.packs === "both") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: data.packsList,
            totalPrice: data.prices.both,
            originalPrice: data.prices.both_original,
          };
        });
      }
    }
  };

  const handleFetchPrice = async () => {
    await axiosPublic
      .get(`accounts/purchasable_price/?phone_number=${props.data.phoneNumber}`)
      .then((res) => {
        // console.log(res.data);
        setData((prevState) => {
          return {
            ...prevState,
            prices: res.data,
          };
        });
      })
      .catch((err) => {
        setMuiAlert({
          show: true,
          type: "error",
          message: "لطفا دوباره تلاش کنید",
        });
      });
  };

  const handleFetchUserId = async () => {
    await axiosPublic
      .get(`accounts/user_insta_id/?phone_number=${props.data.phoneNumber}`)
      .then((res) => {
        // console.log(res.data);
        setData((prevState) => {
          return {
            ...prevState,
            previousId: res.data.data.instagram_id,
            newId: res.data.data.instagram_id,
          };
        });
      })
      .catch((err) => {
        setMuiAlert({
          show: true,
          type: "error",
          message: "لطفا دوباره تلاش کنید",
        });
      });
  };

  const handleEditInstaId = async () => {
    // console.log("edit fired");
    if (data.newId.length) {
      // console.log("go to edit");
      try {
        const editResponse = await axiosPrivate.put(
          "/accounts/user_insta_id/",
          { instagram_id: data.newId }
        );
        if (editResponse.status === 200) {
          setData((prevState) => {
            return { ...prevState, user_info: editResponse.data.data };
          });
          const gatewayStatus = await sendInfoToGateway();
        }
      } catch (err) {
        if (err.response.status === 401) {
          navigate("/register");
        }
        setMuiAlert({
          show: true,
          type: "error",
          message: "لطفا دوباره تلاش کنید",
        });
        return { status: 400, data: err };
      }
    }
  };

  const sendInfoToGateway = async () => {
    let pack = "";
    let pack_fa = "";
    if (data.selectedPacks.length === 1) {
      pack = data.selectedPacks[0].value;
      if (pack === "aerobic") {
        pack_fa = "هوازی";
      } else if (pack === "fullbody") {
        pack_fa = "فول‌بادی";
      }
    } else if (data.selectedPacks.length === 2) {
      pack = "both";
      pack_fa = "فول‌بادی + هوازی";
    }

    const requestData = {
      package: pack,
      description: `حق اشتراک شرکت در دوره ${pack_fa} دکتر مهرداد`,
      callback_url: "https://doctormehrdad.com/verify-purchase",
    };
    await axiosPrivate
      .post("accounts/gateway_info/", requestData)
      .then((res) => {
        // Set local storage and redirect
        if (res.status === 200) {
          setButtonLoading(false);
          if (res.data.message === "purchase allowed") {
            window.location.href = `https://www.zarinpal.com/pg/StartPay/${res.data.data.authority}`;
            return { status: 200, data: res.data };
          } else if (res.data.message === "pack is free for current user") {
            navigate("/verify-purchase");
          }
        }
      })
      .catch((err) => {
        setButtonLoading(false);
        if (err.response.status === 400) {
          setMuiAlert({
            show: true,
            type: "error",
            message: "لطفا برای پرداخت دوباره تلاش کنید",
          });
          props.stateChanger("checkNumber");
          return { status: 400, data: err };
        } else if (err.response.status === 403) {
          setMuiAlert({
            show: true,
            type: "error",
            message: "این دوره در حال حاضر برای شما فعال است",
          });
          setTimeout(() => {
            navigate("/dashboard");
          }, 3000);
          return { status: 403, data: err };
        }
      });
  };

  const handleSubmit = async () => {
    setOpenDialog(false);

    let validationOutput = checkValidation();
    if (validationOutput.isValid) {
      setButtonLoading(true);
      try {
        const loginResponse = await axiosPublic.post("accounts/login/", {
          phone_number: props.data.phoneNumber,
          password: data.password,
        });

        if (
          loginResponse.status === 200 &&
          loginResponse.data.message === "logged in successfully"
        ) {
          localStorage.setItem("token", loginResponse.data.access);

          // var editIdStatus = {};
          if (data.newId !== data.previousId) {
            // console.log("id should update");
            const editIdStatus = await handleEditInstaId();
            // console.log(editIdStatus);
          } else {
            setMuiAlert({
              show: true,
              type: "success",
              message: "شما در حال انتقال به درگاه پرداخت هستید",
            });
            const gatewayStatus = await sendInfoToGateway();
          }

          // if (editIdStatus.status === 200) {
          //   setMuiAlert({
          //     show: true,
          //     type: "success",
          //     message: "شما در حال انتقال به درگاه پرداخت هستید",
          //   });
          // const gatewayStatus = await sendInfoToGateway();
          // } else {
          // props.stateChanger("checkNumber");
          // }

          // if (data.totalPrice > 0) {
          // Assuming sendInfoToGateway returns a promise
          // const gatewayStatus = await sendInfoToGateway();
          // } else if (data.totalPrice === 0) {
          //   navigate("/verify-purchase?Status=OK");
          // }
        }
      } catch (err) {
        if (
          err.response.status === 400 &&
          err.response.data.message === "invalid username or password"
        ) {
          setMuiAlert({
            show: true,
            type: "error",
            message: "شماره‌ی همراه یا رمز عبور صحیح نمی‌باشد",
          });
          setButtonLoading(false);
        }
      }
    }
  };

  const handleForgotPassword = async () => {
    await axiosPublic
      .post("accounts/send_password_reset_code/", {
        phone_number: props.data.phoneNumber,
      })
      .then((res) => {
        if (
          res.status === 200 &&
          (res.data.message === "code sent" ||
            res.data.message === "previous code is valid")
        ) {
          setMuiAlert({
            show: true,
            type: "success",
            message: "کد تأیید برای شما ارسال شد",
          });
          props.stateChanger("forgetPassword", props.data);
        }
      })

      // Catch errors if any
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err.response.data.message === "password changed recently"
        ) {
          setMuiAlert({
            show: true,
            type: "error",
            message: "رمز عبور به تازگی تغییر یافته‌است",
          });
        }
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    handleFetchPrice();
    handleFetchUserId();
  }, []);

  useEffect(() => {
    handleSetSelectedPacks(); // Trigger handleSetSelectedPacks when data.prices changes
  }, [data.prices]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 5 seconds set the show value to false
      setMuiAlert({ ...muiAlert, show: false });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [muiAlert]);

  return (
    <Grid2 container xs={12} justifyContent={"center"}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper elevation={5} sx={styles.paper}>
          <Grid2 container xs={12} sx={styles.innerCntnr}>
            <Grid2 xs={12} sx={styles.text}>
              لطفا آیدی اینستاگرام خود را در صورت لزوم تغییر داده، و رمز عبور
              خود را وارد کنید
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                variant="outlined"
                label="آیدی اینستاگرام جدید"
                value={data.newId}
                autoFocus
                fullWidth
                onChange={handleChange("newId")}
                helperText={"در صورت لزوم این بخش را تغییر"}
              />
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                variant="outlined"
                type={validation.showPassword ? "text" : "password"}
                label="رمز عبور"
                value={data.password}
                // autoFocus
                fullWidth
                onChange={handleChange("password")}
                onKeyDown={handleKeyDown}
                helperText={
                  validation.showPasswordError &&
                  "لطفا رمز عبور خود را وارد کنید"
                }
                error={!validation.isPasswordValid}
                InputProps={{
                  endAdornment: data.password && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {validation.showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid2>
            <Grid2 xs={12} sx={styles.textFldCntnr}>
              <TextField
                label="دوره"
                select
                fullWidth
                value={data.selectedPacks}
                onChange={handleChange("packs")}
                helperText={
                  validation.showPacksError && "لطفا دوره را انتخاب کنید"
                }
                error={!validation.isPacksValid}
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((option) => (
                        <Chip key={option.value} label={option.label} />
                      ))}
                    </Box>
                  ),
                }}
              >
                {data.packsList.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid2>
            {data.prices.invites > 0 ? (
              <>
                <Grid2 xs={12} sx={styles.textFldCntnr}>
                  <span style={{ fontWeight: 600 }}>
                    {data.prices.invites} نفر
                  </span>{" "}
                  به دعوت شما به باشگاه Fit With Doctor پیوسته‌اند. به همین دلیل
                  برای شما <span style={{ fontWeight: 600 }}>تخفیف ویژه</span>{" "}
                  در نظر گرفته‌ایم:
                </Grid2>
                <Grid2 xs={12} sx={styles.textFldCntnr}>
                  مبلغ:
                  <span css={styles.originalPrice}>
                    {data.originalPrice} تومان
                  </span>
                  <span>‌با تخفیف</span>
                  <span css={styles.finalPrice}>
                    {data.totalPrice / 10} تومان
                  </span>
                </Grid2>
              </>
            ) : (
              <Grid2 xs={12} sx={styles.textFldCntnr}>
                مبلغ:
                {data.selectedPacks.length === 2 && (
                  <span css={styles.originalPrice}>798000 تومان</span>
                )}
                {data.selectedPacks.length === 2 && <span>‌با تخفیف</span>}
                <span css={styles.finalPrice}>
                  {data.totalPrice / 10} تومان
                </span>
              </Grid2>
            )}

            <Grid2 xs={12} sx={styles.buttonCntnr}>
              <LoadingButton
                variant="contained"
                size="large"
                loading={buttonLoading}
                // onClick={handleSubmit}
                onClick={() => {
                  setOpenDialog(true);
                }}
                sx={styles.button}
              >
                پرداخت
              </LoadingButton>
            </Grid2>
            <Grid2
              container
              xs={12}
              justifyContent={"flex-end"}
              sx={styles.buttonCntnr}
            >
              <Button
                variant="text"
                onClick={handleForgotPassword}
                sx={styles.forgetBtn}
              >
                رمز خود را فراموشی کرده‌ام
              </Button>
            </Grid2>
          </Grid2>
          <Snackbar
            open={muiAlert.show}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
              {muiAlert.message}
            </Alert>
          </Snackbar>

          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>حواست باشه!</DialogTitle>
            <DialogContent>
              <p>
                بعد از اینکه پرداخت رو انجام دادی، حتما به پیج هایی که برات
                پیامک میشه توی اینستاگرام درخواست بده، تا بتونی توی دوره شرکت
                کنی!
              </p>
            </DialogContent>
            <DialogActions>
              <Grid2
                container
                xs={12}
                justifyContent={"center"}
                sx={{ px: "1rem", mb: "1rem" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  sx={styles.button}
                >
                  باشه!
                </Button>
              </Grid2>
            </DialogActions>
          </Dialog>
        </Paper>
      )}
    </Grid2>
  );
}
