/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Paper,
  Alert,
  Snackbar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosPublic from "../../common/axiosPublic";
import { LoadingButton } from "@mui/lab";

const styles = {
  paper: {
    width: "100%",
    maxWidth: "32rem",
    m: { xs: "1rem", sm: 0 },
  },
  innerCntnr: {
    p: { xs: "1.5rem", md: "2rem" },
  },
  text: {
    mb: "1.5rem",
  },
  textFldCntnr: {
    mb: "1rem",
  },
  button: {
    color: "#fff",
    width: "100%",
  },
  originalPrice: css`
    text-decoration: line-through;
    font-size: 0.85rem;
    margin: 0 0.5rem;
  `,
  finalPrice: css`
    font-weight: 600;
    margin: 0 0.5rem;
  `,
};

export default function ForgetPassword(props) {
  const [data, setData] = useState({
    phoneNumber: "",
    password: "",
    code: "",
  });
  const [validation, setValidation] = useState({
    isCodeValid: true,
    showCodeError: false,
    isPasswordValid: true,
    showPasswordError: false,
    showPassword: false,
    isValid: false,
  });
  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const navigate = useNavigate();

  const checkValidation = () => {
    let tempValidation = {
      isCodeValid: true,
      showCodeError: false,
      isPasswordValid: true,
      showPasswordError: false,
      isValid: true,
    };

    if (data.code.length !== 6) {
      tempValidation = {
        ...tempValidation,
        isCodeValid: false,
        showCodeError: true,
        isValid: false,
      };
    }

    if (data.password.length < 8) {
      tempValidation = {
        ...tempValidation,
        isPasswordValid: false,
        showPasswordError: true,
        isValid: false,
      };
    }
    setValidation((prevState) => {
      return { ...prevState, ...tempValidation };
    });
    return tempValidation;
  };

  const handleChange = (parameter) => (event) => {
    setData((prevState) => {
      return { ...prevState, [parameter]: event.target.value };
    });
  };

  const handleClickShowPassword = () =>
    setValidation((previousState) => {
      return { ...previousState, showPassword: !validation.showPassword };
    });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  const handleSubmit = async () => {
    let validationOutput = checkValidation();
    if (validationOutput.isValid) {
      setButtonLoading(true);
      await axiosPublic
        .post("accounts/verify_reset_password/", {
          phone_number: props.data.phoneNumber,
          new_password: data.password,
          verification_code: data.code,
        })
        .then((res) => {
          if (
            res.status === 200 &&
            res.data.message === "password changed successfully"
          ) {
            localStorage.setItem("token", res.data.access);
            setMuiAlert({
              show: true,
              type: "success",
              message: "تغییر رمز با موفقیت انجام شد",
            });
            setButtonLoading(false);
            navigate("/dashboard");
          }
        })

        // Catch errors if any
        .catch((err) => {
          if (
            err.response.status === 400 &&
            err.response.data.message === "invalid code"
          ) {
            setMuiAlert({
              show: true,
              type: "error",
              message: "کد وارد شده صحیح نمی‌باشد",
            });
            setButtonLoading(false);
          }
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (props.packs) {
      if (props.packs === "fullbody") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: [data.packsList[0]],
            totalPrice: data.packsList[0].price,
          };
        });
      } else if (props.packs === "aerobic") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: [data.packsList[1]],
            totalPrice: data.packsList[0].price,
          };
        });
      } else if (props.packs === "both") {
        setData((prevState) => {
          return {
            ...prevState,
            selectedPacks: data.packsList,
            totalPrice: 4990000,
          };
        });
      }
    }
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 5 seconds set the show value to false
      setMuiAlert({ ...muiAlert, show: false });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [muiAlert]);

  return (
    <Grid2 container xs={12} justifyContent={"center"}>
      <Paper elevation={5} sx={styles.paper}>
        <Grid2 container xs={12} sx={styles.innerCntnr}>
          <Grid2 xs={12} sx={styles.text}>
            لطفا کد ارسال شده به شماره همراه خود، و رمز عبور جدید خود را وارد
            کنید
          </Grid2>
          <Grid2 xs={12} sx={styles.textFldCntnr}>
            <TextField
              fullWidth
              label="کد بازیابی"
              placeholder="کد ارسال‌شده به شماره همراه خود را وارد کنید"
              autoFocus
              value={data.name}
              onChange={handleChange("code")}
              helperText={validation.showCodeError && "لطفا کد معتبر وارد کنید"}
              error={!validation.isCodeValid}
            />
          </Grid2>
          <Grid2 xs={12} sx={styles.textFldCntnr}>
            <TextField
              variant="outlined"
              type={validation.showPassword ? "text" : "password"}
              label="رمز عبور جدید"
              fullWidth
              onChange={handleChange("password")}
              onKeyDown={handleKeyDown}
              helperText={
                validation.showPasswordError &&
                "رمز عبور باید حداقل 8 کاراکتر باشد"
              }
              error={!validation.isPasswordValid}
              InputProps={{
                endAdornment: data.password && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {validation.showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid2>
          <Grid2 xs={12} sx={styles.buttonCntnr}>
            <LoadingButton
              variant="contained"
              size="large"
              loading={buttonLoading}
              onClick={handleSubmit}
              sx={styles.button}
            >
              تغییر رمز
            </LoadingButton>
          </Grid2>
        </Grid2>
        <Snackbar
          open={muiAlert.show}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
            {muiAlert.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Grid2>
  );
}
