import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import RegisterForm from "./registerForm";
import CheckNumber from "./checkNumber";
import LoginAndPurchase from "./loginAndPurchase";
import ForgetPassword from "./forgetPassword";
import Login from "./login";
import axios from "../../common/axiosPrivate";
import axiosPublic from "../../common/axiosPublic";

const styles = {
  cntnr: {
    minHeight: "calc(100vh - 14.85rem)",
    mt: "4rem",
    borderRadius: "1rem",
  },
};

export default function RegisterPage(props) {
  const [data, setData] = useState({});
  const [component, setComponent] = useState("checkNumber");
  const [searchParams] = useSearchParams();
  const p = searchParams.get("p");
  const t = searchParams.get("t");

  const navigate = useNavigate();

  const checkLoggedIn = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await axios
        .get("/accounts/token/verify/", {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.status === 200) {
            if (p) {
              setComponent("checkNumber");
            } else {
              navigate("/dashboard");
            }
          }
        })
        .catch((err) => {});
    }
  };

  const handleChangeState = (state, data) => {
    setData(data);
    setComponent(state);
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  return (
    <Grid2
      container
      xs={12}
      justifyContent="center"
      alignContent="center"
      sx={styles.cntnr}
    >
      {component === "checkNumber" ? (
        <CheckNumber
          packs={p}
          requestType={t}
          stateChanger={handleChangeState}
          data={data}
        />
      ) : component === "RegisterForm" ? (
        <RegisterForm packs={p} stateChanger={handleChangeState} data={data} />
      ) : component === "loginAndPurchase" && p ? (
        <LoginAndPurchase
          packs={p}
          stateChanger={handleChangeState}
          data={data}
        />
      ) : component === "forgetPassword" ? (
        <ForgetPassword stateChanger={handleChangeState} data={data} />
      ) : (
        component === "login" && (
          <Login stateChanger={handleChangeState} data={data} />
        )
      )}
    </Grid2>
  );
}
