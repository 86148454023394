const merchant_id = "8ed3e513-6027-4b88-ba47-e6bd042b3144";

const months = {
  "01": "فروردین",
  "02": "اردیبهشت",
  "03": "خرداد",
  "04": "تیر",
  "05": "مرداد",
  "06": "شهریور",
  "07": "مهر",
  "08": "آبان",
  "09": "آذر",
  10: "دی",
  11: "بهمن",
  12: "اسفند",
};

const numericDateToAlphebet = (date) => {
  const dateParts = date.split("-");
  return { year: dateParts[0], month: months[dateParts[1]], day: dateParts[2] };
};

const numericDateTimeToAlphebet = (date) => {
  const dateParts = date.split(" ")[0].split("-");
  const timeParts = date.split(" ")[1].split(":");
  return {
    year: dateParts[0],
    month: months[dateParts[1]],
    day: dateParts[2],
    hour: timeParts[0],
    minute: timeParts[1],
  };
};

const gregorianToJalaliDate = (date) => {
  let jalaliDate = date.toLocaleDateString("fa-IR-u-nu-latn", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  let jalaliDateFormat = jalaliDate.replace(/\//g, "-");
  return jalaliDateFormat;
};

const gregorianToJalaliDateTime = (date) => {
  let jalaliDate = date.toLocaleTimeString("fa-IR-u-nu-latn", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  let jalaliDateFormat = jalaliDate.replace(/\//g, "-").replace(",", "");
  return jalaliDateFormat;
};

export {
  merchant_id,
  numericDateToAlphebet,
  gregorianToJalaliDate,
  gregorianToJalaliDateTime,
  numericDateTimeToAlphebet,
};
