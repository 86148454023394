/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Button, IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";

const styles = {
  cntnr: {
    backgroundColor: "secondary.dark",
    mt: "4rem",
    color: "#fff",
  },
  widthLmt: {
    maxWidth: "75rem",
    p: "1rem 2rem",
  },
  button: {
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ffffff20",
    },
  },
  enamad: css`
    max-height: 6rem;
    cursor: pointer;
  `,
  rightsTxt: {
    textAlign: "center",
    mt: "1rem",
    fontSize: "0.85rem",
  },
};

export default function Footer() {

  return (
    <Grid2
      container
      xs={12}
      justifyContent="center"
      alignContent="center"
      sx={styles.cntnr}
    >
      <Grid2 container xs={12} sx={styles.widthLmt}>
        <Grid2
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          columnGap={6}
        >
          <Grid2 container xs={12} md="auto" justifyContent="center">
            <a href="https://instagram.com/dr.mehrdad.madadi">
              <Button
                variant="text"
                startIcon={<InstagramIcon />}
                sx={styles.button}
              >
                اینستاگرام دکتر مهرداد
              </Button>
            </a>
          </Grid2>
          <Grid2
            container
            xs={12}
            md="auto"
            justifyContent="center"
            sx={{ mt: { xs: "1rem", md: 0 } }}
          >
            <a
              referrerpolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=412085&Code=WH08ssBrqdgddgdvdiFRWiYtfmnYrj1x"
              rel="noreferrer"
            >
              <img
                referrerpolicy="origin"
                src="https://trustseal.enamad.ir/logo.aspx?id=412085&Code=WH08ssBrqdgddgdvdiFRWiYtfmnYrj1x"
                alt=""
                css={styles.enamad}
                Code="WH08ssBrqdgddgdvdiFRWiYtfmnYrj1x"
              />
            </a>
          </Grid2>
        </Grid2>
        <Grid2 xs={12} sx={styles.rightsTxt}>
          © 1402 تمام حقوق و محتویات این سایت متعلق به دکتر مهرداد است.
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
