/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { CircularProgress, Fade, Paper, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import axiosPrivate from "../../common/axiosPrivate";
import axiosRetry from "axios-retry";

const styles = {
  cntnr: {
    minHeight: "80vh",
  },
  row: {
    textAlign: "center",
  },
  successIcon: {
    fontSize: "3rem",
    color: "primary.main",
  },
  errorIcon: {
    fontSize: "3rem",
    color: "#d91c28",
  },
  link: css`
    text-decoration: none;
    direction: rtl;
  `,
};

export default function VerifyPurchase(props) {
  const [state, setState] = useState("progress");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const authority = searchParams.get("Authority");
  const gatewayStatus = searchParams.get("Status");

  axiosRetry(axiosPrivate, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
      console.log(`retry attempt: ${retryCount}`);
      return retryCount * 1000; // time interval between retries
    },
    retryCondition: (error) => {
      // Conditional check the error status code
      if ([404, 408, 429, 500, 501, 503, 504].includes(error.response.status)) {
        return true;
      } else {
        return false; // Do not retry the others
      }
    },
  });

  const handleVerification = async () => {
    await axiosPrivate
      .post("/accounts/user_packages/", {
        authority: authority,
        status: gatewayStatus,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setState("success");
          setTimeout(() => {
            navigate("/dashboard");
          }, 2000);
        }
        // return { status: 200, data: res.data };
      })
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err.response.data.data?.authority[0]?.includes("already exists")
        ) {
          setState("success");
          setTimeout(() => {
            navigate("/dashboard");
          }, 2000);
        } else {
          setState("error");
          setTimeout(() => {
            navigate("/dashboard");
          }, 20000);
        }
      });
  };

  useEffect(() => {
    handleVerification();
  }, []);

  return (
    <Grid2
      container
      xs={12}
      sx={styles.cntnr}
      alignContent={"center"}
      rowGap={3}
    >
      {state === "success" ? (
        <>
          <Grid2 xs={12} sx={styles.row}>
            <Typography sx={{ fontWeight: 600 }}>
              پرداخت با موفقیت ثبت شد!
            </Typography>
          </Grid2>
          <Grid2 xs={12} sx={styles.row}>
            <CheckCircleIcon sx={styles.successIcon} />
          </Grid2>
        </>
      ) : state === "progress" ? (
        <>
          <Grid2 xs={12} sx={styles.row}>
            در حال بررسی وضعیت پرداخت
          </Grid2>
          <Grid2 xs={12} sx={styles.row}>
            <Fade
              in={state === "progress"}
              style={{
                transitionDelay: state === "progress" ? "500ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Grid2>
        </>
      ) : (
        state === "error" && (
          <>
            <Grid2 xs={12} sx={styles.row}>
              <Typography sx={{ fontWeight: 600 }}>
                به نظر می‌رسد پرداخت با مشکل مواجه شده است!
              </Typography>
            </Grid2>
            <Grid2
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={[
                styles.row,
                {
                  display: "flex",
                },
              ]}
            >
              <Grid2 xs={11} md={9}>
                <Typography>
                  ابتدا صبر کنید تا به صفحه‌ی داشبورد خود منتقل شوید.
                  <br />
                  درصورتی که دوره برای شما اضافه نشده بود، و مبلغ از حساب شما
                  کسر شده است، تا 72 ساعت صبر کنید، و اگر همچنان مبلغ به حساب
                  شما مرجوع نشد، به شماره{" "}
                  <a href="tel: +989921078890" css={styles.link}>
                    <span
                      style={{ unicodeBidi: "bidi-override", direction: "ltr" }}
                    >
                      +989921078890
                    </span>
                  </a>{" "}
                  در واتساپ پیام دهید.
                </Typography>
              </Grid2>
            </Grid2>
            <Grid2 xs={12} sx={styles.row}>
              <CancelIcon sx={styles.errorIcon} />
            </Grid2>
          </>
        )
      )}
    </Grid2>
  );
}
