/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Alert, Button, Paper, Snackbar, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axiosPublic from "../../common/axiosPublic";
import theme from "../../theme";

const styles = {
  paper: {
    width: "100%",
    maxWidth: "32rem",
    m: { xs: "1rem", sm: 0 },
  },
  innerCntnr: {
    p: { xs: "1.5rem", md: "2rem" },
  },
  text: {
    mb: "1.5rem",
  },
  mark: css`
    color: ${theme.palette.secondary.dark};
    background: linear-gradient(180deg, transparent 40%, #7cf2f7 0);
    margin: 0 0.2rem;
  `,
  textFldCntnr: {
    mb: "1rem",
  },
  txtFld: {
    "& .MuiInputBase-input": {
      fontFamily: "Arial, sans-serif !important",
    },
  },
  button: {
    color: "#fff",
    width: "100%",
  },
};

export default function CheckNumber(props) {
  const [data, setData] = useState({ phoneNumber: "" });
  const [validation, setValidation] = useState({
    isValid: true,
    showError: false,
    errorMessage: "",
  });
  const [muiAlert, setMuiAlert] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [packageAvailability, setPackageAvailability] = useState(null);

  const navigate = useNavigate();

  const checkValidation = () => {
    if (data.phoneNumber.length !== 11) {
      setValidation({
        ...validation,
        isValid: false,
        showError: true,
        errorMessage: "لطفا شماره تلفن معتبر وارد کنید",
      });
      return false;
    } else if (isNaN(data.phoneNumber)) {
      setValidation({
        ...validation,
        isValid: false,
        showError: true,
        errorMessage: "لطفا اعداد را به صورت لاتین وارد کنید",
      });
      return false;
    } else {
      setValidation({ ...validation, isValid: true, showError: false });
      return true;
    }
  };

  const handleChange = (e) => {
    setData({ ...data, phoneNumber: e.target.value });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMuiAlert((prevState) => {
      return { ...prevState, show: false };
    });
  };

  const checkPackageAvailable = async () => {
    if (props.packs) {
      if (props.packs === "both") {
        await axiosPublic
          .get("packs/active_pack/?category=fullbody")
          .then((res) => {
            if (res.status === 200) {
              if (res.data.state === "inactive") {
                setPackageAvailability("inactive");
                // setPackageAvailability((prevState) => {
                //   return res.data.state;
                // });
              } else {
                axiosPublic
                  .get("packs/active_pack/?category=aerobic")
                  .then((res) => {
                    if (res.status === 200) {
                      setPackageAvailability((prevState) => {
                        return res.data.state;
                      });
                    }
                  })
                  .catch((err) => {
                    // setPackageAvailability(false);
                  });
              }
            }
          })
          .catch((err) => {});
      } else {
        await axiosPublic
          .get(`packs/active_pack/?category=${props.packs}`)
          .then((res) => {
            if (res.status === 200) {
              setPackageAvailability((prevState) => {
                return res.data.state;
              });
            }
          })
          .catch((err) => {
            // setPackageAvailability(false);
          });
      }
    } else {
      // setPackageAvailability(false);
    }
  };

  const handleSubmit = () => {
    let isValid = checkValidation();
    if (isValid) {
      if ((props.packs && packageAvailability) || !props.packs) {
        setButtonLoading(true);
        axiosPublic
          .post(
            "accounts/check_number/",
            // props.requestType
            // ?
            {
              phone_number: data.phoneNumber,
              request_type: props.requestType,
            }
            // : {
            //     phone_number: data.phoneNumber,
            //   }
          )
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data.message);
              console.log(packageAvailability);
              setButtonLoading(false);
              if (
                res.data.message === "signup allowed" ||
                res.data.message === "code already has been sent"
              ) {
                if (packageAvailability === "active") {
                  props.stateChanger("RegisterForm", data);
                } else {
                  navigate("/");
                }
              } else if (res.data.message === "login allowed") {
                if (packageAvailability === "active") {
                  props.stateChanger("loginAndPurchase", data);
                } else {
                  props.stateChanger("login", data);
                }
              }
            }
          })

          // Catch errors if any
          .catch((err) => {
            setButtonLoading(false);
            if (err.response.data.message === "invalid phone number") {
              setMuiAlert({
                show: true,
                type: "error",
                message: "شماره تلفن وارد شده معتبر نیست",
              });
            } else if (err.response.data.message === "user not found") {
              setMuiAlert({
                show: true,
                type: "error",
                message:
                  "شما ثبت نام نکرده‌اید. برای ثبت نام به صفحه اصلی مراجعه کنید.",
              });
            } else if (err.response.data.message === "purchase unavailable") {
              setMuiAlert({
                show: true,
                type: "error",
                message: "زمان ثبت نام در دوره‌ها فرا نرسیده است.",
              });
            } else {
              setMuiAlert({
                show: true,
                type: "error",
                message: "لطفا دوباره امتحان کنید",
              });
            }
          });
      }
    }
  };

  useEffect(() => {
    checkPackageAvailable();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 5 seconds set the show value to false
      setMuiAlert({ ...muiAlert, show: false });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [muiAlert]);

  return (
    <Grid2 container xs={12} justifyContent={"center"}>
      <Paper elevation={5} sx={styles.paper}>
        <Grid2 container xs={12} sx={styles.innerCntnr}>
          <Grid2 xs={12} sx={styles.text}>
            شماره تلفن خود را،<mark css={styles.mark}>با اعداد لاتین</mark>‌وارد
            کنید
          </Grid2>
          <Grid2 xs={12} sx={styles.textFldCntnr}>
            <TextField
              fullWidth
              label="شماره تلفن"
              placeholder="09xx"
              value={data.phoneNumber}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              helperText={validation.showError && validation.errorMessage}
              error={!validation.isValid}
              sx={styles.txtFld}
            />
          </Grid2>
          <Grid2 xs={12} sx={styles.buttonCntnr}>
            <LoadingButton
              variant="contained"
              size="large"
              loading={buttonLoading}
              sx={styles.button}
              onClick={handleSubmit}
            >
              <span>ورود</span>
            </LoadingButton>
          </Grid2>
        </Grid2>
        <Snackbar
          open={muiAlert.show}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseAlert} severity={muiAlert.type}>
            {muiAlert.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Grid2>
  );
}
