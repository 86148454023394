import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function SlideCard(props) {
  const styles = {
    cntnr: {
      width: "100%",
      height: "100%",
      color: "#fff",
      backgroundColor: "#000",
    },
    blackLyr: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "#000",
    },
    gradientLyr: {
      position: "absolute",
      width: "100%",
      height: "100%",
      right: 0,
      backgroundImage: {
        xs: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1))",
        md: "linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,1))",
      },
      backgroundSize: {
        xs: "100% calc(100vw * 0.4)",
        sd: "100% 100%",
      },
      mt: { xs: "calc(100vw * 0.163)", sd: 0 },
    },
    rightPrt: {
      position: "relative",
      backgroundColor: "#1143fc50",
      width: "0%",
      height: "100%",
    },
    leftPrt: {
      position: "relative",
      backgroundImage: `url(${props.backgroundImage})`,
      // backgroundSize: "cover",
      backgroundSize: { xs: "contain", sd: "cover" },
      backgroundRepeat: "no-repeat",
      backgroundPosition: { xs: "center top", sd: "center top -50px" },
      // backgroundColor: "#fd4c7a50",
      width: "100%",
      height: "100%",
    },
    overlayTxt: {
      position: "relative",
      alignContent: { xs: "flex-end", md: "center" },
      justifyContent: { xs: "center", md: "flex-start" },
      textAlign: { xs: "center", md: "left" },
      p: "2rem",
      zIndex: 1,
    },
    titleTxt: {
      fontSize: { xs: "2rem", sd: "3rem" },
      fontWeight: 750,
      color: "primary.main",
    },
    descriptionTxt: {
      pt: "0.5rem",
      fontSize: "1.15rem",
      fontWeight: "500",
    },
    mobileBlackCntnr: {
      backgroundColor: "#000",
      display: { xs: "flex", md: "none" },
      height: "5rem",
      width: "100%",
    },
  };

  return (
    <Grid2 container xs={12}>
      <Grid2 container xs={12} sx={styles.cntnr}>
        <Grid2 sx={styles.rightPrt}>
          <Grid2 sx={styles.blackLyr}></Grid2>
        </Grid2>
        <Grid2 container sx={styles.leftPrt}>
          <Grid2 sx={styles.gradientLyr}></Grid2>
          <Grid2 container xs={12} md={5} sx={styles.overlayTxt}>
            <Grid2 xs={12} sx={styles.titleTxt}>
              {props.title}
            </Grid2>
            <Grid2 xs={12} sx={styles.descriptionTxt}>
              {props.description.map((paragraph) => {
                return (
                  <>
                    {paragraph}
                    <br />
                  </>
                );
              })}
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      {/* <Grid2 container xs={12} sx={styles.mobileBlackCntnr}></Grid2> */}
    </Grid2>
  );
}
