import mem from "mem";

import axiosPublic from "./axiosPublic";
import getCookie from "./getCookie";

// const getCSRF = async () => {
//   await axiosPublic
//     .get("/accounts/csrf_token/", {})
//     .then((res) => {
//       if (res.status === 200) {
//         csrfToken = res.data;
//         return res.data;
//       }
//     })
//     .catch((err) => {});
// };

const refreshTokenFn = async () => {
  var csrfToken = getCookie("csrftoken");

  try {
    const response = await axiosPublic.post(
      "/accounts/token/refresh/",
      {},
      {
        headers: {
          "x-csrftoken": csrfToken,
        },
      }
    );

    const accessToken = response.data.access;
    if (accessToken) {
      localStorage.removeItem("token");
    }

    localStorage.setItem("token", accessToken);

    // * dispatch an event whenever token is refreshed, so we can add an event listener to check when token is updated
    window.dispatchEvent(new Event("storage"));

    return accessToken;
  } catch (error) {
    localStorage.removeItem("token");
  }
  // }
};

const maxAge = 1000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
