import React from "react";
import ReactDOM from "react-dom/client";

import { ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import theme from "./theme";
import reportWebVitals from "./reportWebVitals";

import HomePage from "./sections/home/homePage";
import RegisterPage from "./sections/auth/registerPage";
import DashboardPage from "./sections/dashboard/dashboardPage";
import VerifyPurchase from "./sections/dashboard/verifyPurchase";

import Footer from "./components/footer";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/register" exact element={<RegisterPage />} />
          <Route path="/dashboard" exact element={<DashboardPage />} />
          <Route path="/verify-purchase" exact element={<VerifyPurchase />} />
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  </CacheProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
